<template>
  <section id="pricing" class="pb-8">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Biaya SPMB</h1>
              <h1 class="font-weight-light">Biaya SPB PerGelombang</h1>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="text-center"
              v-for="biaya in allBiaya"
              :key="biaya.id"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card raised shaped :elevation="hover ? 10 : 4">
                  <div class="header">
                    <h1 class="font-weight-light pt-3">
                      {{ biaya.title }}
                    </h1>
                    <h1 class="text--lighten-2 display-1 font-weight-bold mt-3">
                      {{ biaya.price | currency }}
                    </h1>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                  >
                    <path
                      fill="#283e79"
                      fill-opacity="1"
                      d="M0,64L80,90.7C160,117,320,171,480,176C640,181,800,139,960,117.3C1120,96,1280,96,1360,96L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                    ></path>
                  </svg>
                  <!-- <ul>
                    <li
                      v-for="(feature, j) in plano.features"
                      :key="j"
                      :class="sizeText"
                    >
                      <p
                        class="secondary--text mb-0 ml-3"
                        style="text-align: start"
                      >
                        {{ feature.text }}
                      </p>
                    </li>
                  </ul> -->
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-rounded text-light">
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 144.54 17.34"
        preserveAspectRatio="none"
        fill="currentColor"
      >
        <path
          d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"
        />
      </svg> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        fill="currentColor"
      >
        <path
          d="M0,64L80,90.7C160,117,320,171,480,181.3C640,192,800,160,960,138.7C1120,117,1280,107,1360,101.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Pricing",
  data: () => ({
    // planos: [
    //   {
    //     title: "Gelombang 1",
    //     price: "Rp.100,00",
    //   },
    //   {
    //     title: "Gelombang 2",
    //     price: "Rp.150,00",
    //   },
    //   {
    //     title: "Gelombang 3",
    //     price: "Rp.250,00",
    //   },
    // ],
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    sizeText() {
      const size = {
        xs: "subtitle-1",
        sm: "text-h6",
        md: "text-h5",
        xl: "text-h4"
      }[this.$vuetify.breakpoint.name];
      console.log(size);
      return size ? { [size]: true } : {};
    },
    ...mapGetters("biaya", ["allBiaya"])
  },
  created() {
    this.loadBiaya();
  },
  methods: {
    ...mapActions("biaya", ["loadBiaya"])
  }
};
</script>
<style lang="scss">
$main_color: #283e79;
</style>
<style scoped>
.header {
  background-color: #283e79;
  color: white;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: rgb(112, 44, 238);
  z-index: -1;
}

#pricing {
  z-index: 0;
}

.content {
  z-index: 1;
}
svg {
  overflow: hidden;
}
section {
  position: relative;
}
</style>
