<template>
  <section id="about">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7">
              <h1 class="font-weight-light display-2">Langkah</h1>
              <h1 class="font-weight-light display-1 mb-3">
                Menjadi Mahasiswa UNIKARTA
              </h1>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon2.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <ul>
                    <li>Warga Negara Indonesia</li>
                    <li>
                      Calon Mahasiswa harus melukukan pendaftaran/buat akun pada
                      website SPMB UNIKARTA
                    </li>
                    <li>Calon mahasiswa login ke website PMB</li>
                    <li>Calon mahasiswa mengisi formulis pendaftaran</li>
                  </ul>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon1.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <ul>
                    <li>
                      Calon mahasiswa melakukan pembayaran melalu Bank
                      pembayaran yang di pilih universitas Kutai Kartanegara.
                    </li>
                  </ul>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon3.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <ul>
                    <li>
                      Calon mahasiswa mencetak kartu ujian online dan mengikuti
                      ujian seleksi online di kampus.
                    </li>
                    <li>
                      Memiliki kesehatan yang memadai sehingga tidak mengganggu
                      kelancaran proses pembelajaran di program studi yang
                      dipilih.
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/img/ill1.svg"
                class="d-block ml-auto mr-auto"
                max-width="400px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "About"
};
</script>
