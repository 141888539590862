<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">Panitia SPMB UNIKARTA</h1>
              <h3 class="font-weight-light mt-3">
                Universitas Kutai Kartangera
              </h3>
              <h3 class="font-weight-light mt-3">
                Jl. Gunung Kombeng No.27 Kab. Kutai Kartanegara, Kalimantan
                Timur
              </h3>
              <h3 class="font-weight-light mt-3">
                Telefone: 085800009055
              </h3>
              <h3 class="font-weight-light">
                Email: info@unikarta.ac.id
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-img
                src="@/assets/img/contact-us.svg"
                class="d-block ml-auto mr-auto"
                max-width="350px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>
export default {
  data: () => ({
    lazy: false
  })
};
</script>
