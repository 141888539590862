<template>
  <section id="download">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <v-img
                src="@/assets/img/ill2.svg"
                class="d-block ml-auto mr-auto"
                max-width="350px"
              />
            </v-col>
            <v-col cols="12" sm="8" class="white--text text-left">
              <h1 class="font-weight-light display-2 mb-2">
                Profil Program Studi
              </h1>
              <h1 class="font-weight-light">
                Universitas Kutai Kartanegara
              </h1>
              <v-simple-table fixed-header height="400px">
                <thead>
                  <tr>
                    <th class="text-left">
                      Nomor
                    </th>
                    <th class="text-left">
                      Fakultas
                    </th>
                    <th class="text-left">
                      Prodi
                    </th>
                    <th class="text-left">
                      Jenjang
                    </th>
                    <th class="text-left">
                      Akreditasi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Fakultas Ekonomi</td>
                    <td>Manajemen & Bisini</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Fakultas Ilmu Sosial & Ilmu Politik</td>
                    <td>Ilmu Administrasi Negara</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Fakultas Keguruan & Ilmu Pendidikan</td>
                    <td>Teknologi Pendidikan</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK SEKALI</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Fakultas Keguruan & Ilmu Pendidikan</td>
                    <td>Pendidikan Bahasa Inggris</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Fakultas Pertanian</td>
                    <td>Agroteknologi</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Fakultas Pertanian</td>
                    <td>Agribisnis</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Fakultas Teknik</td>
                    <td>Teknik Pertambangan</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Fakultas Hukum</td>
                    <td>Ilmu Hukum</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Fakultas Agama Islam</td>
                    <td>Pendidikan Agama Islam</td>
                    <td class="text-center">S1</td>
                    <td class="text-center">BAIK SEKALI</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "Download"
};
</script>
<style scoped>
#download {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 700px;
}

#download .container,
#download .row {
  height: 100%;
}
</style>
