<template>
  <section id="hero">
    <v-parallax dark src="@/assets/img/bgHero.jpg" height="750">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" xl="8">
          <h1 class="display-2 font-weight-bold mb-4">SPMB UNIKARTA</h1>
          <h4 class="font-weight-light">Seleksi Penerimaan Mahasiswa Baru</h4>
        </v-col>
      </v-row>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">
                Lagi Bingung Cari Tempat Kuliah Yang Tempatnya Nyaman Serta
                Kampusnya Terakreditasi
              </h1>
              <h1 class="font-weight-light">
                Gabung Sekarang juga di Program Penerimaan Mahasiswa Baru
                Universitas Kutai Kartanegara Tenggarong.
              </h1>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      features: [
        {
          img: require("@/assets/img/icon2.png"),
          title: "Layanan",
          text: "Pendaftaran Mudah dan Cepat"
        },
        {
          img: require("@/assets/img/icon1.png"),
          title: "Biaya",
          text: "Pembayaran DPP/semester bisa diangsur dalam 1 semester"
        },
        {
          img: require("@/assets/img/icon3.png"),
          title: "Program Studi Terakreditasi",
          text: "Program Sarjana, Program Pascasarjana."
        }
      ]
    };
  }
};
</script>
<style>
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
